<template>
  <div>
    <el-form
      label-position="top"
      :model="task"
      ref="triggerWorkflowTaskForm"
      label-width="100px"
      :rules="rules"
      class="form-container"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <PageHeader style="padding-left: 0" :title="__('Trigger Workflow')" />
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top"
            :label="__('Workflow Name')"
            prop="task_name"
          >
            {{ task.task_name }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="passedParamsFound" type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top"
            :label="__('Passed Parameters')"
            prop="passed_param"
          >
            <div
              v-for="(passedParam, index) in passedParams"
              :key="index"
              style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            >
              <div style="display:flex;">
                <h4>
                  {{ getPassedParameterNameFromId(passedParam.variable_id) }}
                </h4>
              </div>
              <div style="display:flex; width: 500px">
                <el-input
                  v-model="
                    $data.$passedParams[
                      getPassedParameterNameFromId(passedParam.variable_id)
                    ]
                  "
                >
                </el-input>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-else type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item>
            <div class="info">
              <div class="warning-exclamation" />
              <div style="margin-left: 5px; display: flex">
                <div>
                  {{ __("There are no passed parameters for this workflow") }}
                </div>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-button :loading="loading" class="submitBtn" @click="submit">{{
              __("Run")
            }}</el-button>
            <el-button class="cancelBtn" @click="$emit('cancel')"
              >{{ __("Cancel") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "triggerWorkflow",
  components: { PageHeader },
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      $passedParams: {},
      rules: {}
    };
  },
  mounted() {
    _.map(this.passedParams, passedParam => {
      this.$set(
        this.$data.$passedParams,
        this.getPassedParameterNameFromId(passedParam.variable_id),
        ""
      );
    });
  },
  computed: {
    ...mapState("tasks", {
      loading: state => state.loading
    }),

    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables",
      getPassedParameterNameFromId: "getPassedParameterNameFromId"
    }),

    passedParams() {
      return this.task.workflow_task.data.passed_params.data;
    },

    passedParamsFound() {
      return !_.isEmpty(this.passedParams);
    }
  },
  methods: {
    ...mapActions("tasks", {
      triggerWorkflow: "triggerWorkflow"
    }),
    submit() {
      this.$refs.triggerWorkflowTaskForm.validate(valid => {
        if (valid) {
          this.triggerWorkflow({
            workflow_id: this.task.task_id,
            source: "web",
            ...this.$data.$passedParams
          })
            .then(() => {
              this.$message({
                type: "success",
                message: __("workflow has been triggered")
              });
              this.$emit("cancel");
            })
            .catch(err => {
              console.log(err);
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";

.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
</style>
