var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"triggerWorkflowTaskForm",staticClass:"form-container",attrs:{"label-position":"top","model":_vm.task,"label-width":"100px","rules":_vm.rules}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('PageHeader',{staticStyle:{"padding-left":"0"},attrs:{"title":_vm.__('Trigger Workflow')}})],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('el-form-item',{staticClass:"form_label_top",attrs:{"label":_vm.__('Workflow Name'),"prop":"task_name"}},[_vm._v(" "+_vm._s(_vm.task.task_name)+" ")])],1)],1),(_vm.passedParamsFound)?_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('el-form-item',{staticClass:"form_label_top",attrs:{"label":_vm.__('Passed Parameters'),"prop":"passed_param"}},_vm._l((_vm.passedParams),function(passedParam,index){return _c('div',{key:index,staticStyle:{"display":"flex","align-items":"center","width":"100%","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.getPassedParameterNameFromId(passedParam.variable_id))+" ")])]),_c('div',{staticStyle:{"display":"flex","width":"500px"}},[_c('el-input',{model:{value:(
                  _vm.$data.$passedParams[
                    _vm.getPassedParameterNameFromId(passedParam.variable_id)
                  ]
                ),callback:function ($$v) {_vm.$set(_vm.$data.$passedParams, 
                    _vm.getPassedParameterNameFromId(passedParam.variable_id)
                  , $$v)},expression:"\n                  $data.$passedParams[\n                    getPassedParameterNameFromId(passedParam.variable_id)\n                  ]\n                "}})],1)])}),0)],1)],1):_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('el-form-item',[_c('div',{staticClass:"info"},[_c('div',{staticClass:"warning-exclamation"}),_c('div',{staticStyle:{"margin-left":"5px","display":"flex"}},[_c('div',[_vm._v(" "+_vm._s(_vm.__("There are no passed parameters for this workflow"))+" ")])])])])],1)],1),_c('el-form-item',[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12,"offset":6}},[_c('el-button',{staticClass:"submitBtn",attrs:{"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.__("Run")))]),_c('el-button',{staticClass:"cancelBtn",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.__("Cancel"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }